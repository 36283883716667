import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './guards/auth.guard';
import { RoleGuardService } from './guards/role.guard';
import { SessionGuardService } from './guards/session.guard';
import { USER_ROLE } from './common/common.consts';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import("./external/external.module").then(m => m.ExternalModule),
    canActivate: [SessionGuardService]
  },
  {
    path: 'onboarding',
    loadChildren: () => import("./onboarding/onboarding.module").then(m => m.OnboardingModule),
  },
  {
    path: 'app',
    loadChildren: () => import("./customer/customer.module").then(m => m.CustomerModule),
    canActivate: [AuthGuardService],
    // data: {
    //   roleGuard: { role: USER_ROLE.ROLE_CUSTOMER_USER, redirectTo: '/' },
    // },
  },
  {
    path: 'admin',
    loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule),
    // canActivate: [AuthGuardService, RoleGuardService],
    // data: {
    //   roleGuard: { role: USER_ROLE.ROLE_SYSTEM_ADMIN, redirectTo: '/' },
    // },
  },
  {path: 'login/disabled', loadChildren: () => import("./login-disabled/login-disabled.module").then(m => m.LoginDisabledModule)},
  { path: '404', loadChildren: () => import("./not-found/not-found.module").then(m => m.NotFoundModule) },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule],
  providers: [AuthGuardService, RoleGuardService, SessionGuardService]
})
export class AppRoutingModule { }
export const RoutingComponents = [];
