import { Subject } from "rxjs";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { ILoginResponse } from "./external/login/login.interface";
import { USER_ROLE } from "./external/external.types";
import { environment } from "src/environments/environment";

const TOKEN = 'bearerToken';
const EXPIRATION = 'expiration';
const USER = 'user';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private _user;
  private avatarUrl = new Subject<string>();
  public avatarUrl$ = this.avatarUrl.asObservable();

  get customerId(): any {
    return this.getUserData()?.customerId;
  }

  get userId(): any {
    return this.getUserData()?.userId;
  }

  get username(): any {
    return this.getUserData()?.username;
  }

  constructor(private httpClient: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) { }

  getUserData() {
    if (this._user) return this._user;
    else if (localStorage.getItem(USER)) return JSON.parse(localStorage.getItem(USER));
    else if (sessionStorage.getItem(USER)) return JSON.parse(sessionStorage.getItem(USER));
    else return null;
  }

  isSystem() {
    return this._user && this._user.customerType === 'SYSTEM' && this._user.roles.include(USER_ROLE.ROLE_SYSTEM_ADMIN);
  }

  isCustomer() {
    return this._user && this._user.customerType === 'CUSTOMER' && this._user.roles.includes(USER_ROLE.ROLE_CUSTOMER_USER);
  }

  // updateAvatarUrl(url: string): void {
  //   let user = this.getUserData();
  //   user.avatarUrl = url;
  //   this.avatarUrl.next(url);
  //   if (localStorage.getItem(TOKEN)) localStorage.setItem(USER, JSON.stringify(user));
  //   else if (sessionStorage.getItem(TOKEN)) sessionStorage.setItem(USER, JSON.stringify(user));
  //   this._user = user;
  // }

  setSession(token, expiration, user) {
    localStorage.setItem(TOKEN, token);
    localStorage.setItem(EXPIRATION, expiration.toString());
    this.setUserInSession(user);
  }

  setTempSession(token, expiration, user) {
    sessionStorage.setItem(TOKEN, token);
    sessionStorage.setItem(EXPIRATION, expiration.toString());
  }

  setUserInSession(res: ILoginResponse) {
    const user: ILoginResponse = res;
    if (localStorage.getItem(TOKEN)) localStorage.setItem(USER, JSON.stringify(user));
    else if (sessionStorage.getItem(TOKEN)) sessionStorage.setItem(USER, JSON.stringify(user));
    this._user = user;
  }

  clearSession() {
    localStorage.removeItem(TOKEN)
    localStorage.removeItem(EXPIRATION);
    sessionStorage.removeItem(TOKEN)
    sessionStorage.removeItem(EXPIRATION);
    localStorage.removeItem(USER);
    sessionStorage.removeItem(USER);
  }

  isLoggedIn() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.location.origin === 'http://localhost:4200') {
        localStorage.setItem(TOKEN, environment.token);
        localStorage.setItem(EXPIRATION, new Date(new Date().getTime() + 60 * 60 * 24 * 24 * 1000).getTime().toString());
        localStorage.setItem(USER, JSON.stringify(environment.user));
        this._user = environment.user;
      }
      let tempSession = sessionStorage.getItem('bearerToken') !== null && Number(sessionStorage.getItem(EXPIRATION)) > Date.now();
      let session = localStorage.getItem('bearerToken') !== null && Number(localStorage.getItem(EXPIRATION)) > Date.now();
      return tempSession || session;
    }
    if (isPlatformServer(this.platformId)) {
      return true;
    }
  }

}