export const environment = {
  production: true,
  googleAnalyticsId: '',
  sentryId: '',
  apiUrl: 'https://signalsapi.forscreens.in/api',
  authUrl: 'https://signalsapi.forscreens.in/api',
  authService: 'https://signalsapi.forscreens.in',
  configUrl: 'https://signalsapi.forscreens.in/api',
  clientServiceUrl: 'https://signalsapi.forscreens.in/api',
  settingsServiceUrl: 'https://signalsapi.forscreens.in/api',
  token: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMDc3MzgwMSIsImN1c3RvbWVyX2lkIjoyLCJ1c2VyX2lkIjoyLCJhdXRob3JpdGllcyI6WyJST0xFX0NVU1RPTUVSX1VTRVIiXSwiaWF0IjoxNzQzNTM2NzEzLCJleHAiOjE3NDM2MjMxMTN9.Hb8ydDCtM60HcS4b0awLJ3lmLnLHfnko-ifAiQVTP6k',
  user: {"customerId":2,"accountId":"147992579","customerType":"CUSTOMER","customerStatus":"TRIAL","userId":2,"gitUserId":"10773801","emailId":"shalinee43@gmail.com","name":"ShalineeSingh","username":"ShalineeSingh","avatarUrl":"https://avatars.githubusercontent.com/u/10773801?v=4","userStatus":"ACTIVE","bearerToken":"Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMDc3MzgwMSIsImN1c3RvbWVyX2lkIjoyLCJ1c2VyX2lkIjoyLCJhdXRob3JpdGllcyI6WyJST0xFX0NVU1RPTUVSX1VTRVIiXSwiaWF0IjoxNzQzMzYwMzYyLCJleHAiOjE3NDM0NDY3NjJ9.lv4JP-hPYOdM5TvH7jCDiTLSoKZWTOqn_kHTa9JDi-Y","roles":["ROLE_CUSTOMER_USER"],"initSync":"created","sourceCodeType":"GITHUB"}
};
