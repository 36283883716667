import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AppService } from '../app.service';

@Injectable()
export class SessionGuardService implements CanActivate {

  constructor(public router: Router, private appService: AppService) { }

  canActivate(): boolean {
    if (this.appService.isLoggedIn()) {
      if (this.appService.isSystem()) this.router.navigate(['/app/admin']);
      else this.router.navigate(['/app/cycle-time']);
      return false;
    }
    return true;
  }

}