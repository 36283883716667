export enum USER_ROLE {
  CUSTOMER_ADMIN = 'Admin',
  CUSTOMER_USER = 'User',
  CUSTOMER_MANAGER = 'Manager',
}

export enum USER_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum INVITATION_STATUS {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  EXPIRED = 'expired',
}

export const USER_ROLE_LIST = {
  DEVELOPER: 'Developer',
  TEAM_LEAD_EM: 'Team Lead / Engineering Manager',
  PRODUCT_MANAGER: 'Product Manager',
  ENGINEERING_DIRECTOR: 'Engineering Director',
  VP_CTO: 'VP / CTO',
  OTHER: 'Other',
}

export const API_ERROR_MSG = 'Something went wrong. Please try again later.';
export const EMPTY_ERROR_MSG = 'No data available';

function createKeyMap(arr: string[], type: 'camelCase' | 'upperCase' = 'upperCase') { // returns {key: VALUE} object
  const a = arr.reduce((acc, val) => {
    acc[val] = type === 'camelCase' ? changeToCamelCase(val) : val.toUpperCase();
    return acc;
  }, {});
  return a;
}

function changeToCamelCase(str: string) {
  const str1 = str.toLowerCase();
  return str1.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
}

export const SingularPluralsMap = {
  'minute': { singular: $localize`minute`, plural: $localize`minutes` },
  'hour': { singular: $localize`hour`, plural: $localize`hours` },
  'day': { singular: $localize`day`, plural: $localize`days` },
  'week': { singular: $localize`week`, plural: $localize`weeks` },
  'wk': { singular: $localize`wk`, plural: $localize`wk` },
  'month': { singular: $localize`month`, plural: $localize`months` },
  'year': { singular: $localize`year`, plural: $localize`years` },
  'yr': { singular: $localize`yr`, plural: $localize`yrs` },
  'mo': { singular: $localize`mo`, plural: $localize`mo` },
  'hr': { singular: $localize`hr`, plural: $localize`hrs` },
  'min': { singular: $localize`min`, plural: $localize`mins` },
  'sec': { singular: $localize`sec`, plural: $localize`secs` },
  'second': { singular: $localize`second`, plural: $localize`seconds` },
  'millisecond': { singular: $localize`millisecond`, plural: $localize`milliseconds` },
  'w': { singular: $localize`w`, plural: $localize`w` },
  'd': { singular: $localize`d`, plural: $localize`d` },
  'h': { singular: $localize`h`, plural: $localize`m` },
  'm': { singular: $localize`m`, plural: $localize`h` },
  's': { singular: $localize`s`, plural: $localize`s` },
  'ms': { singular: $localize`ms`, plural: $localize`ms` },
}