import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideClientHydration } from '@angular/platform-browser';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { AppRoutingModule, RoutingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './common/http.interceptor';
import en from '@angular/common/locales/en';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { enUS } from 'date-fns/locale';

import { NZ_I18N, NZ_DATE_LOCALE, en_US } from 'ng-zorro-antd/i18n';

import { MicroSentryModule } from '@micro-sentry/angular';
import { NotificationService } from './common/services/notification.service';
// import { ServiceWorkerModule, SwPush, SwUpdate } from '@angular/service-worker';
// import { PwaService } from './pwa.service';
import { NzModalModule } from 'ng-zorro-antd/modal';

registerLocaleData(en);
// const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

@NgModule({
  declarations: [
    AppComponent,
    RoutingComponents,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzModalModule,
    // NgxGoogleAnalyticsModule.forRoot('G-C0NJ69QWKS'),
    // MicroSentryModule.forRoot({
    //   dsn: "https://b6c4c9d26984423381a321832c8141ca@app.glitchtip.com/5154"
    // }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: true, registrationStrategy: 'registerWhenStable:2000' }), //change to true before deployment
  ],
  providers: [
    provideClientHydration(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_DATE_LOCALE, useValue: enUS },
    // { provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true },
    NotificationService,
    // SwPush,
    // SwUpdate,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
