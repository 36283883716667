import { Injectable } from '@angular/core';
import { NzIconService } from 'ng-zorro-antd/icon';

@Injectable({
  providedIn: 'root'
})
export class IconsRegistryService {
  constructor(private iconService: NzIconService) {}

  registerIcons(): void {
    this.iconService.addIconLiteral('custom:genie', `<?xml version="1.0"?><svg fill="#5d5c5c" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 11.5 2 C 9.57 2 8 3.57 8 5.5 C 8 7.43 9.57 9 11.5 9 C 13.43 9 15 7.43 15 5.5 C 15 3.57 13.43 2 11.5 2 z M 11.5 4 C 12.327 4 13 4.673 13 5.5 C 13 6.327 12.327 7 11.5 7 C 10.673 7 10 6.327 10 5.5 C 10 4.673 10.673 4 11.5 4 z M 7 10 C 5.346 10 4 11.346 4 13 L 4 17 L 7.2304688 17 C 8.4114687 20.877 11.497 23 16 23 C 16.96 23 18.361266 22.937531 19.447266 22.394531 L 21.498047 21.369141 L 19.351562 20.5625 C 16.854563 19.6255 16.220266 18.082 16.072266 17 L 19 17 L 19 13 C 19 11.346 17.654 10 16 10 L 7 10 z M 7 12 L 16 12 C 16.552 12 17 12.449 17 13 L 17 15 L 14.070312 15 L 14.037109 15.964844 C 13.951109 18.344844 14.891719 19.936047 16.136719 20.998047 C 16.091719 20.999047 16.046 21 16 21 C 12.125 21 9.7606094 19.242344 8.9746094 15.777344 L 8.7988281 15 L 6 15 L 6 13 C 6 12.449 6.449 12 7 12 z"/></svg>
    </svg>`);
    
    // Add more custom icons here
  }
}