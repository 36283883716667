import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
// import { SwUpdate } from '@angular/service-worker';
import { NzIconService } from 'ng-zorro-antd/icon';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription, merge, of, fromEvent, map } from 'rxjs';
import { AnalyticsService } from './common/services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isCollapsed = false;
  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;

  constructor(public route: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private renderer: Renderer2,
    private msg: NzMessageService,
    // private swUpdate: SwUpdate,
    private nzIconService: NzIconService,
    private analyticsService: AnalyticsService) {
    nzIconService.changeAssetsSource('ant-icons/');
  }

  ngOnInit(): void {
    this.checkNetworkStatus();
    this.checkForUpdates();
  }
  
  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      let loader = this.renderer.selectRootElement('#main-loader');
      if (loader.style.display != "none") loader.style.display = "none"; //hide loader
    }
  }
  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        if (!status) this.msg.error($localize`You are offline`);
        this.networkStatus = status;
      });
  }

  checkForUpdates() {
    // this.swUpdate.available.subscribe(event => {
    //   if (confirm("New version available. Load New Version?")) {
    //     window.location.reload();
    //   }
    // });
  }
}
