import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private router: Router,  private meta: Meta,
    private titleService: Title) {
    // Track route changes for page views
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.trackPageView(event.urlAfterRedirects);
    });
  }

  /**
   * Track a page view
   * @param path The path of the page
   */
  public trackPageView(path: string): void {
    if (typeof gtag !== 'undefined') {
      gtag('event', 'page_view', {
        page_path: path,
        page_location: window.location.href
      });
    }
  }

  /**
   * Track an event
   * @param eventName Name of the event
   * @param eventParams Additional parameters for the event
   */
  public trackEvent(eventName: string, eventParams: any = {}): void {
    if (typeof gtag !== 'undefined') {
      gtag('event', eventName, eventParams);
    }
  }

  public setMetaTags(title, desc): void {
    const imageUrl = '../../../assets/images/logo-dark.svg';
    this.titleService.setTitle(title);
    this.meta.addTags([
      { name: 'description', content: desc },
      { property: 'og:title', content: title },
      { property: 'og:description', content: desc },
      { property: 'og:image', content: imageUrl },
      { property: 'og:url', content: window.location.href },
      { property: 'og:type', content: 'profile' },
      { name: 'twitter:card', content: imageUrl },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: desc },
      { name: 'twitter:image', content: imageUrl },
      { name: 'twitter:url', content: window.location.href }
    ]);
  }
}
