import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, Data } from '@angular/router';
import { AppService } from '../app.service';
// import { USER_ROLES } from '../common/common.consts';


@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(
    private router: Router,
    private appService: AppService
  ) {
    this.router = router;
  }

  public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {
    return this.isRouteAccessible(activatedRouteSnapshot.data);
  }

  public canLoad(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {
    return this.isRouteAccessible(activatedRouteSnapshot.data);
  }

  private isRouteAccessible(data: Data) {
    const config = data.roleGuard;
    console.log(config);
    // if(config.role === USER_ROLES.ROLE_SYSTEM_ADMIN && this.appService.isSystem()) return true;
    // else if(config.role === USER_ROLES.ROLE_CUSTOMER_USER && this.appService.isCustomer()) return true;
    // this.router.navigate([config.redirectTo]);
    return false;
  }
}