import { NgForm } from '@angular/forms';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export function checkServerErrors<T>(response) {
  if (response && response.error && response.error !== null) {
    throw new Error(response.message)
  } else return response.data as T;
}

export function validateForm(form: NgForm) {
  if (form.valid) {
    return true;
  } else {
    Object.values(form.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
  }
  return false;
}

export function getEnumKeyByEnumValue(myEnum, enumValue) {
  let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : null;
}

export function getCheckedStatus(value): boolean {
  if (!value) return null;
  if (value === 1) return true;
  else if (value === 2) return false;
}

export function calculateAge(date): number {
  const timeDiff = Math.abs(Date.now() - new Date(Number(date)).getTime());
  return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
}


export function formatDuration(val: number, showSeconds: boolean = false): string {
  const dur = dayjs.duration(val, 'seconds');
  const parts = [];
  let duration = '';

  if (dur.days() > 0) {
    parts.push(`${dur.days()}d`);
  }
  if (dur.hours() > 0) {
    parts.push(`${dur.hours()}h`);
  }
  if (dur.minutes() > 0) {
    parts.push(`${dur.minutes()}m`);
  }
  if (dur.seconds() > 0 && showSeconds) {
    parts.push(`${dur.seconds()}s`);
  }
  duration = parts.join(' ');
  if (duration === '' || duration === ' ') {
    duration = '0s';
  }

  return duration;
}

export function formatAxisDuration(val: number): string {
  const dur = dayjs.duration(val, 'seconds');
  if (dur.asDays() >= 1) {
    return `${Math.floor(dur.asDays())}d`;
  } else if (dur.asHours() >= 1) {
    return `${Math.floor(dur.asHours())}h`;
  } else {
    return `${Math.floor(dur.asMinutes())}m`;
  }
}
// convert seconds to hours / days and round off
export function getRoundedTimeValue(value, unit) {
  return value ? Math.round(dayjs.duration(value, 'seconds').as(unit)) : null;
}

export function convertToSeconds(value, unit) {
  return value ? dayjs.duration(value, unit).asSeconds() : null;
}

export function hexToRgba(hex: string, opacity: number): string {
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function convertKeysToSnakeCase(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    acc[snakeCase(key)] = obj[key];
    return acc;
  }, {});
}

function snakeCase(key: string): string {
  return key.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase();
}

export function removeEmptyValues(obj) {
  // obj can be obj or array or string or number, remove empty arry from obj
  if (!obj) return obj;
  if (Array.isArray(obj)) {
    return obj.filter(item => {
      if (Array.isArray(item) && item.length === 0) return false;
      return item !== null && item !== undefined;
    }).map(item => removeEmptyValues(item));
  }
  if (typeof obj !== 'object') return obj;
  return Object.keys(obj).reduce((acc, key) => {
    const value = removeEmptyValues(obj[key]);
    if (value !== null && value !== undefined && value !== '' &&
      !(Array.isArray(value) && value.length === 0)) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export function cleanChartData(dataPoint) {
  let value = null;
  if (dataPoint === -1) value = 0;
  else if (dataPoint) value = dataPoint;
  else if (dataPoint === 0) value = 0;
  return value;
}

